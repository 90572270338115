.language-switch {
  display: flex;
  gap: 5px;

  &__item {
    font-size: 0.8rem;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 0.5;

    &--active {
      opacity: 1;
    }
  }
}
