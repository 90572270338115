.vf-page-item.dots{
    min-width: 36px;
    text-align: center;
}

.vf-pagination {
    align-items: center;

    &.position {

        &__center {
            justify-content: center;
        }
        &__left {
            justify-content: flex-start;
        }
        &__right {
            justify-content: flex-end;
        }
    }
}