@use '../../../styles/variables' as *;

.user__create__options {
        display: flex;
        margin-top: 20px;
        justify-content: space-around;
  
        &__item {
            width: 48%;
          input[type='radio'] {
            display: none;
            &:checked {
              & ~ label {
                background-color: $primary__solar-yellow;
                color: #000;
              }
            }
          }
  
          label {
            background-color: $gray-200;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            font-size: 0.9rem;
            font-weight: 400;
            cursor: pointer;
            color: $dark;
            margin-bottom: 0;
            transition: all 0.3s ease-out;
  
            &:hover {
              background-color: $gray-300;
              color: black;
            }
          }
        }
      }
