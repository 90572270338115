@use '../../../styles/variables' as *;

.toolbar {
  display: flex;
  gap: 1rem;
  padding: 4px 0;
  position: sticky;
  top: 57px; // header size
  background-color: #fff;
  border-top: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
  z-index: 10;

  &__divider {
    flex: 1;
  }
}
