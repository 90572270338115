@use '../../../styles/variables' as *;

$labelFontSize: 0.8rem;

.comments {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  &__item {
    line-height: 1.2;
    font-size: 0.9rem;
    margin-bottom: 1rem;

    &__header {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      margin-bottom: 5px;
      font-size: $labelFontSize;

      &__author {
        font-weight: 500;
      }

      &__date {
        font-weight: 300;
        color: $gray-500;
      }

      &__source {
        flex-grow: 1;
        text-align: right;
        color: $gray-600;
      }
    }

    &__content {
      font-weight: 300;
      line-height: 1.2;
      text-align: justify;
      color: $gray-600;
    }
  }

  &__form {
    label {
      margin-top: 0 !important;
      font-size: $labelFontSize;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
    }
  }
}
