@use '../../../styles/variables' as *;

.badge {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 4px;

  &--red {
    border-color: $vattenfall-dark-red;
    color: $vattenfall-dark-red;
  }

  &--green {
    border-color: $vattenfall-dark-green;
    color: $vattenfall-dark-green;
  }

  &--blue {
    border-color: $vattenfall-mid-blue;
    color: $vattenfall-mid-blue;
  }

  &--grey {
    border-color: $gray-600;
    color: $gray-600;
  }
}
