@use '../../../../styles/variables' as *;

.history {
  &__item {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    &__top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
      line-height: 1rem;
      font-size: 0.8rem;
      .content {
        font-weight: 500;
      }
      .date {
        font-weight: 200;
      }
    }

    &__bottom {
      font-weight: 200;
      line-height: 1.25rem;
    }
  }
}
