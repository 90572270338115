.issue-badge {
  white-space: nowrap;

  &--status,
  &--priority {
    padding: 2px 8px;
  }

  &--status {
    border: none;
    color: #fff;
  }

  &--priority {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
