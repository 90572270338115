.switch-container {
    margin-top: 1rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ccc;
    transition: 0.3s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 3.5px;
    bottom: 1.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + .slider {
    background-color: #1964a3;

  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .label {
    position: absolute;
    left: 100%;
    width: max-content;
    line-height: 30px;
    margin-left: 10px;
}

