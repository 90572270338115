@use '../../styles/variables' as *;

$navigation-base-size: 100px;
$navigation-base-size-smaller: 60px;
$navigation-desktop-threshold: 729px;
$navigation-font-size: 10px;

$navigation-base-bg-color: $white;
$navigation-base-color: $vattenfall-black;
$navigation-base-active-color: $vattenfall-mid-blue;
$navigation-base-active-bg-color: $white;

$navigation-desktop-bg-color: $vattenfall-mid-blue;
$navigation-desktop-color: $white;
$navigation-desktop-active-bg-color: $vattenfall-dark-blue;
$navigation-desktop-active-color: $white;

nav.bottom-nav {
  > ul {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    transition: width 0.3s ease-out;

    //give nav it's own compsite layer
    will-change: transform;
    transform: translateZ(0);

    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: $navigation-base-size;
    box-shadow: $box-shadow;

    background-color: $navigation-base-bg-color;
    list-style-type: none;

    > li {
      flex-grow: 1;
      text-align: center;
      justify-content: center;

      > a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-decoration: none;
        font-size: 10px;
        color: $navigation-base-color;
        padding: 0 3px;
        i,
        img,
        svg {
          padding: 0;
          height: 20px;
          width: 20px;
          margin: 5px auto;
          filter: invert(0.2);
        }
        &:hover,
        &.active {
          color: $navigation-base-active-color;
          i,
          img,
          svg {
            padding: 0;
            height: 20px;
            width: 20px;
            margin: 5px auto;
            filter: invert(45%) sepia(70%) saturate(7051%) hue-rotate(193deg)
              brightness(86%) contrast(75%); // hehe :)
          }
        }
      }
    }
  }
  @media all and (min-width: $navigation-desktop-threshold) {
    > ul {
      top: 57px;
      width: $navigation-base-size;
      height: calc(100% - 57px);
      flex-direction: column;
      box-shadow: none;
      background-color: $navigation-desktop-bg-color;

      > li {
        flex-grow: initial;
        height: $navigation-base-size;
        transition: height 0.3s ease-out;

        > a {
          color: $navigation-desktop-color;
          height: 100%;
          i,
          img,
          svg {
            filter: invert(1);
          }
          &:hover,
          &.active {
            color: $navigation-desktop-active-color;
            background-color: $navigation-desktop-active-bg-color;
            i,
            img,
            svg {
              filter: invert(1);
            }
          }
        }
      }
      > li.bottom-nav__link--smaller {
        height: $navigation-base-size-smaller;
      }
    }
  }
}

.secondary-nav {
  > ul {
    position: relative;
    z-index: 999;
    padding: 0;
    margin: 0;
    list-style-type: none;

    > li {
      position: relative;
      height: 60px;
      border-bottom: 1px solid $vattenfall-light-grey;
      color: $navigation-base-color;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 40px;
        padding: 10px 0;
        color: inherit;
        display: block;
        content: '\203a';
      }

      &:hover:after {
        color: $navigation-base-active-color;
      }

      > a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        text-decoration: none;
        font-size: 16px;
        color: inherit;

        &:hover,
        &.active {
          color: $navigation-base-active-color;
          i,
          img,
          svg {
            padding: 0;
            height: 20px;
            width: 20px;
            margin: 0 15px 0 0;
            filter: invert(45%) sepia(70%) saturate(7051%) hue-rotate(193deg)
              brightness(86%) contrast(75%); // nomination to hack of the year
          }
        }

        &:active {
          transform: translate(0, 2px);
        }

        i,
        img,
        svg {
          padding: 0;
          height: 20px;
          width: 20px;
          margin: 0 15px 0 0;
          filter: invert(0.2);
        }
      }
    }
  }
}

@media all and (min-width: 728px) {
  .secondary-nav {
    width: 66.666%;
  }
}
@media all and (min-width: 1400px) {
  .secondary-nav {
    width: 50%;
  }
}

.bottom-nav__link__title {
  visibility: visible;
  opacity: 1;
  height: auto;
  transition: all 0.15s 0.15s ease-out;

  @media all and (min-width: $navigation-desktop-threshold) {
    &--not-visible {
      visibility: hidden;
      opacity: 0;
      height: 0;
      transition: none;
    }
  }
}

.bottom-nav__sizer {
  display: none;
  position: absolute;
  bottom: 20px;
  right: 0px;
  background: $navigation-desktop-active-bg-color;
  width: 26px;
  padding: 6px 12px;
  border-radius: 50% 0 0 50%;
  cursor: pointer;

  img {
    height: 14px;
    filter: invert(1);
  }

  @media all and (min-width: $navigation-desktop-threshold) {
    display: grid;
    place-content: center;
  }
}

@media all and (min-width: $navigation-desktop-threshold) {
  .bottom-nav__only-icons {
    > ul {
      width: $navigation-base-size-smaller !important;
    }
  }
}

.bottom-nav__sizer {
  display: none;
  position: absolute;
  bottom: 20px;
  right: 0px;
  background: $navigation-desktop-active-bg-color;
  width: 26px;
  padding: 6px 12px;
  border-radius: 50% 0 0 50%;
  cursor: pointer;

  img {
    height: 14px;
    filter: invert(1);
  }

  @media all and (min-width: $navigation-desktop-threshold) {
    display: grid;
    place-content: center;
  }
}

@media all and (min-width: $navigation-desktop-threshold) {
  .bottom-nav__only-icons {
    > ul {
      width: $navigation-base-size-smaller !important;
    }
  }
}
