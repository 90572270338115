.vf-btn {
  &:focus {
    outline: none;
  }
}

a.vf-btn.disabled {
  background-color: #eeeeee;
  color: #767676;
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none;
}

.vf-btn--with-icon {
  padding: 9px 30px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  img {
    height: 0.9rem;
  }

  &.vf-btn__secondary {
    img,
    svg {
      filter: invert(99%) sepia(7%) saturate(17%) hue-rotate(22deg)
        brightness(115%) contrast(100%);
    }
  }

  &:hover {
    &::before {
      content: none;
    }
    &.vf-btn__tertiary {
      img,
      svg {
        filter: invert(99%) sepia(7%) saturate(17%) hue-rotate(22deg)
          brightness(115%) contrast(100%);
      }
    }
  }

  &:disabled,
  &.disabled {
    img,
    svg {
      filter: invert(51%) sepia(0%) saturate(0%) hue-rotate(63deg)
        brightness(90%) contrast(92%);
    }
  }
}
