@use '../../../styles/variables' as *;

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.action-btn {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  font-weight: 400;
  color: $dark;
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
  transition: background-color 0.3s;
  vertical-align: middle;
  white-space: nowrap;

  img {
    width: 0.9rem;
  }

  img,
  svg {
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover:not(:disabled) {
    background-color: darken($gray-100, 5);
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &--disabled {
    pointer-events: none;
    cursor: auto;
    color: $gray-400;

    svg {
      fill: $gray-400 !important;
    }
  }

  &--blue {
    color: $secondary;

    &:hover:not(:disabled) {
      background-color: $vattenfall-light-blue;
    }

    svg {
      fill: $secondary;
    }
  }

  &--white {
    background-color: rgba(#fff, 0.3);
    color: #fff;

    &:hover:not(:disabled) {
      background-color: rgba(#fff, 0.5);
    }

    svg {
      fill: #fff;
    }
  }

  &--light-gray {
    background-color: rgba($gray-200, 0.5);

    &:hover:not(:disabled) {
      background-color: $gray-200;
    }
  }

  &--gray {
    background-color: rgba($gray-300, 0.5);

    &:hover:not(:disabled) {
      background-color: $gray-300;
    }
  }

  &--red {
    color: $vattenfall-dark-red;

    &:hover:not(:disabled) {
      background-color: $vattenfall-light-red;
    }

    svg {
      fill: $vattenfall-dark-red;
    }
  }

  &--green {
    color: $vattenfall-dark-green;

    &:hover:not(:disabled) {
      background-color: $vattenfall-light-green;
    }

    svg {
      fill: $vattenfall-dark-green;
    }
  }
}

.animated {
  & svg {
    animation: rotating 3s linear infinite;
  }
}

.animated-hover:hover {
  & svg {
    animation: rotating 3s linear infinite;
  }
}

.animated-opacity {
  & svg {
    animation: blink-animation 1s steps(5, start) infinite;
  }
}

@keyframes blink-animation {
  to {
    opacity: 0;
  }
}
