@use '../../../styles/variables' as *;

.vf-tab-bar-container {
  .vf-tab-bar {
    justify-content: flex-start !important;

    .vf-tab-bar-item {
      flex-grow: 0 !important;
      padding: 4px 20px !important;

      .vf-tab-bar-link {
        font-weight: 400 !important;
        color: $gray-500 !important;
      }

      &--active {
        .vf-tab-bar-link {
          color: $secondary !important;
        }
      }
    }
  }

  .vf-tab-bar-panels {
    padding-top: 1rem !important;
  }
}
