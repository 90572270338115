@use '../../../styles/variables' as *;

.toasts-wrapper {
  position: fixed;
  top: 67px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
  max-width: calc(100% - 20px);
}

.toast {
  position: relative;
  padding: 0.5rem 1rem;
  padding-right: 40px;
  border-radius: 4px;
  white-space: pre-line;
  width: 400px;
  max-width: 100%;
  font-size: 0.875rem;
  animation: animate-opacity 0.5s ease-out;

  &__title {
    font-weight: 500;
    font-size: 1rem;
  }

  &__close-btn {
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    color: $gray-600;

    &:focus {
      outline: none;
    }
  }

  &-error {
    background: $vattenfall-light-red;
    color: $vattenfall-dark-red;
  }

  &-success {
    background: $vattenfall-light-green;
    color: $green;
  }

  &-info {
    background-color: $vattenfall-light-blue;
    color: $vattenfall-mid-blue;
  }

  &-warning {
    background-color: $vattenfall-light-yellow;
    color: $yellow;
  }
}

@keyframes animate-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
