@use '../../styles/variables' as *;

$text-color: $dark;
$border-color: $vattenfall-light-border-grey;
$border-color--error: $vattenfall-error-red-border;

$text-color--disabled: $gray-700;
$background-color--disabled: $vattenfall-dropdown-input-disabled;

// to unify the styles of all fields for different states (normal, disabled, error)

input.vf-input,
textarea.vf-input,
.vf-dropdown__semantic,
.react-date-picker__wrapper,
.multi-select .vf-input {
  border-color: $border-color !important;
  color: $text-color;
  opacity: 1;
  font-size: 1rem !important;
  padding: 0.5rem 0.75rem !important;

  &:disabled {
    background-color: $background-color--disabled;
    color: $text-color--disabled;

    &:hover {
      cursor: auto;
    }
  }

  &--error,
  &__error {
    border-color: $border-color--error !important;
  }
}

input[type='checkbox'] {
  border-color: $border-color !important;

  &.checkbox__error {
    border-color: $border-color--error !important;
  }

  &:disabled {
    background-color: $background-color--disabled;
    cursor: auto;
  }
}

.multi-select {
  .vf-input {
    border-color: $border-color !important;
    color: $text-color;

    [class*='-placeholder'] {
      color: $text-color;
    }
  }

  &__disabled .vf-input {
    background-color: $background-color--disabled;

    [class*='-placeholder'] {
      color: $text-color--disabled;
    }
  }

  &__error .vf-input {
    border-color: $border-color--error !important;
  }
}
