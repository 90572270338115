@use '../../../styles/variables' as *;

.list-filters {
  &__fields {
    margin-bottom: 20px;

    label {
      font-size: 0.8rem;
      margin-top: 0 !important;
      margin-bottom: 0;
      color: $gray-600;
    }
  }

  &__buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    &__reset-btn {
      color: $secondary !important;

      &:disabled {
        color: $dark !important;
        background-color: transparent !important;
      }
    }

    button:focus {
      outline: none !important;
    }
  }
}
