@use '../../../styles/variables' as *;

.vf-file-upload--dragndrop {
  cursor: pointer;
  text-align: center;
  padding: 0.5rem 1rem !important;
  width: 100% !important;

  .vf-upload__text {
    font-size: 0.95rem !important;
    line-height: 1.2;
    margin: 0.5rem 0 !important;
  }

  .vf-upload__size {
    font-size: 0.7rem !important;
    line-height: 1.2;
    margin-bottom: 0 !important;
  }

  &--disabled {
    cursor: auto;
  }
}

.vf-file-list {
  margin-bottom: 1rem !important;

  .vf-file-list__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    line-height: 1.2 !important;

    .vf-file-list__link {
      font-size: 0.9rem !important;
      line-height: 1 !important;
      color: $dark;
      font-weight: 500;
    }

    .vf-file-list__item-description {
      font-size: 0.75rem !important;
      margin-left: 0 !important;
    }

    &__ctas {
      display: flex;
    }
  }
}
