.modal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9995;
  }

  &__container {
    z-index: 9999;
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 300px;
    max-width: 80%;
    height: auto;
    max-height: 80%;
    overflow: auto;
    border-radius: 4px;
    padding: 1rem 2rem;
    padding-top: 2.5rem;

    &--full-screen {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: none;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    white-space: pre-wrap;
  }

  &__close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    background: transparent;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &__ctas {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
}
