@use '~@vf-dds/vf-dds-vanilla/src/style/_variables.scss' as *;
@forward '~@vf-dds/vf-dds-vanilla/src/style/_variables.scss';

// missing variables (from legacy @vf-alchemy package)
$primary: $primary__solar-yellow;
$secondary: $primary__ocean-blue;
$link-color: $link__color;
$link-font-weight: 500;
$vattenfall-mid-blue: $primary__ocean-blue;
$dark: $text__dark-color;
$white: $primary__aura-white;
$vattenfall-button-blue: $link__color;
$vattenfall-black: $text__dark-color;
