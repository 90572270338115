@use '../../../styles/variables' as *;

.react-date-picker {
  width: 100%;
  height: 52px;

  &__wrapper {
    width: 100%;
    height: 52px;
    padding: 12px 8px 12px 20px;
    border-radius: 4px;
    border: 1px solid $gray-200 !important;
    background-color: #fff;
  }

  &__inputGroup {
    display: flex;
    align-items: center;

    &__input {
      padding-right: 0 !important;
    }
  }

  &__button {
    padding: 2px;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:hover,
  .react-calendar__tile--active:focus {
    background-color: $secondary;
  }

  .react-calendar__tile--now,
  .react-calendar__tile--now:hover,
  .react-calendar__tile--now:focus {
    background-color: lighten($secondary, 50);
  }

  &--disabled {
    .react-date-picker__clear-button {
      display: none;
    }
  }

  &--readonly * {
    color: $dark;
  }

  &--error {
    .react-date-picker__wrapper {
      border-color: $vattenfall-error-red-border !important;
    }
  }

  &__calendar--open,
  &__calendar--closed {
    position: absolute;
  }

  &__button:focus {
    outline: none;
  }
}
