.vf-cookie-banner {
  letter-spacing: normal;
  border-radius: 4px;
  padding: 5px 10px !important;
  padding-right: 40px;

  button {
    cursor: pointer;

    &:not(.vf-cookie-banner__close-btn) {
      background-color: transparent;
      border: none;
      color: #fff;
      white-space: nowrap;
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }
}

@media screen and (min-width: 576px) {
  .vf-cookie-banner {
    font-size: 0.875rem !important;
    padding: 5px 10px !important;
    text-align: left !important;
    padding-right: 40px !important;
  }
}

.vf-cookie-banner__close-btn .vf-icon-close {
  display: grid;

  &::before {
    font-size: 1rem !important;
  }
}
