@use '../../../styles/variables' as *;
@use '../ListTemplate/list-template.scss' as *;

.header-template {
  @include list-grid-common;

  font-size: 0.9em;
  color: $gray-500;
  text-transform: uppercase;

  &--sortable {
    cursor: pointer;

    .order-icon {
      margin-left: 5px;
    }

    &:hover {
      .order-icon {
        visibility: visible !important;
      }
    }
  }
}
