.issue-filters {
  .date-picker {
    flex: 1;
  }

  fieldset legend {
    font-size: 0.8rem !important;
    margin-bottom: 0;
  }

  &__fields-group {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
  }
}
