@use '../../../styles/variables' as *;

.semantic-dropdown__icon {
  position: absolute;
  top: 50%;
  left: 20px;
  pointer-events: none;
  z-index: 2;
  transform: translateY(-50%);
  display: grid;
  place-items: center;

  img {
    min-width: 1rem !important;
  }

  + .vf-dropdown__semantic {
    padding-left: 52px;
  }
}

.vf-dropdown__semantic {
  padding-right: 52px !important;
  background-color: #fff;

  &:focus,
  &:hover {
    outline: none;
    border-color: $gray-300 !important;
  }
}

.vf-dropdown-wrapper__readonly {
  &::after {
    display: none;
  }
}
