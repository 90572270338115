.page-not-found__container {
  text-align: center;
  padding-top: 4rem;

  button.vf-link-with-arrow {
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &.centered {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}
