@use '../../../styles/variables' as *;

.textarea__container {
  position: relative;
}

.textarea__context {
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 12px;
  color: $tertiary__medium-dark-grey;
}
