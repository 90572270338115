@import '../../styles/variables.scss';

.dashboard-outer-container {
  .dashboard-container {
    background: $bg__light-yellow;
    padding: 20px;
    margin: 20px 0;
    padding-bottom: 50px;
    & + .dashboard-container {
      margin-top: 20px;
    }
    &__label {
      font-size: 1rem;
    }
    &__value {
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}
