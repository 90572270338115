@use '../../../styles/variables' as *;

.list-template {
  font-size: 12px;
  line-height: 20px;
  overflow-x: auto;

  &__grid-container {
    min-width: 1200px; // for small screens scroll
  }

  @media (min-width: 1366px) {
    font-size: 14px;
  }
}

@mixin list-grid-common {
  display: grid;
  gap: 10px;
  padding: 3px 5px;
  border-bottom: 1px solid $gray-200;
  align-items: center;
}
