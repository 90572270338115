@use '../../../styles/variables' as *;

.issue-details {
  .main-column-template__header__title {
    label {
      font-weight: 500;
      color: $dark;
    }
  }

  &__info-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1.5rem;
  }

  &__tabs {
    margin-top: 1.5rem;
  }

  &__badges {
    display: flex;
    gap: 0.5rem;
  }

  &__audit {
    span {
      color: $gray-500;
      font-size: 0.8rem;
    }
  }
}
