.form-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;

  &__cancel-btn {
    min-width: auto !important;
  }
}
