.issues-list {
  .toolbar {
    margin-top: 2rem;
    align-items: center;

    label.checkbox__container {
      font-size: 0.9rem;
      margin-bottom: 1px;
    }
  }

  &__group-by {
    > div {
      display: inline-flex;
      align-items: center;
      gap: 5px;
    }

    label {
      font-size: 0.9rem;
      margin-top: 0 !important;
    }

    .vf-dropdown-wrapper {
      height: auto;
      width: auto;

      .vf-dropdown__semantic {
        height: 100%;
        padding: 2px !important;
        padding-left: 5px !important;
        padding-right: 30px !important;
        font-size: 0.9rem !important;
      }

      &::after {
        padding: 10px;
        background-size: 28px;
      }
    }
  }
}
