@use '~@vf-dds/vf-dds-vanilla/src/style/_variables.scss' as *;
@forward './variables';

// used in reboot file
$black: $primary__coal-black;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.75;
$body-color: #222;
$body-bg: #fff;
$paragraph-margin-bottom: 2rem;
$dt-font-weight: $font-weight-bold;
$link-decoration: none;
$link-font: $font__default;
$link-font-style: normal;
$link-hover-decoration: underline;
$table-cell-padding: .75rem;
$table-cell-padding-sm: .3rem;
$table-caption-color: $gray-600;
$label-margin-bottom: .5rem;

@mixin hover {
  &:hover { @content; }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}