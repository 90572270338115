@use '../../styles/variables' as *;

.breadcrumbs {
  &__container {
    margin: 0.5rem 0;
    display: flex;
    font-size: 0.9rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    a {
      color: $gray-500;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__separator {
    margin: 0 0.5rem;
    user-select: none;
    line-height: 1.5;

    img {
      height: 10px;
      filter: invert(83%) sepia(5%) saturate(472%) hue-rotate(169deg)
        brightness(88%) contrast(85%);
    }
  }
}
