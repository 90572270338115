@use '../../../styles/variables' as *;

label.checkbox__container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:has(input[type='checkbox']:disabled) {
    cursor: auto;
    opacity: 0.6;
  }
}

// @alchemy styles used in drop-down checkbox inputs
input[type='checkbox'] {
  appearance: none;
  background: rgb(255, 255, 255);
  border-radius: 4px 4px 4px 4px;
  border: 1px solid rgb(164, 169, 172);
  height: 1rem;
  width: 1rem;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  margin-right: 6px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    transition: transform 0.2s ease-in-out;
    border-bottom: 2px solid rgb(216, 216, 216);
    border-right: 2px solid rgb(216, 216, 216);
    height: 10px;
    width: 6px;
    margin: auto;
    left: 4px;
    top: 1px;
    transform: rotate(45deg) scale(0);
  }

  &:checked {
    &:before {
      transform: rotate(45deg) scale(1);
      border-bottom: 2px solid rgb(78, 75, 72);
      border-right: 2px solid rgb(78, 75, 72);
    }
  }

  &:hover {
    &:before {
      transform: rotate(45deg) scale(1);
    }
  }

  &:disabled.pre-selected::before {
    transform: rotate(45deg) scale(1);
    border-bottom: 3px solid rgb(216, 216, 216);
    border-right: 3px solid rgb(216, 216, 216);
  }

  &:focus {
    outline: none;
  }
}

input[type='checkbox'] {
  & + label {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &:focus {
    & + label {
      opacity: 1;
    }
  }

  &:checked {
    & + label {
      opacity: 1;
    }
  }

  &:disabled {
    & + label {
      opacity: 0.7;
    }
  }
}
