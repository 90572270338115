button > svg:not(:only-child) {
  margin-bottom: 2px;

  &#Trash_alternative_two {
    // trash icon in delete button
    margin-bottom: 3px;
  }

  &#Cross {
    margin-bottom: 0;
  }
}
