@use '../../styles/variables' as *;

.report-an-issue {
  background: $gray-200;
  position: fixed;
  top: calc(50% + 80px); // under the feedback badge
  right: -1rem;
  border-radius: 5px 0 0 5px;
  transition: right .3s ease-out;
  
  a {
    padding: 1rem 1.25rem 1rem .5rem;
    display: grid;
    place-items: center;
  }

  &:hover {
    right: -0.5rem;
  }
}