#login-page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('./assets/images/bg.png');
  background-position: center;
  background-size: cover;

  #login-container {
    position: relative;
    width: 600px;
    height: 450px;
    background: #fff;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .logo {
    position: relative;
    display: inline-block;
    width: 50%;
    margin: 0 25%;
    margin-top: -128px;
  }

  .login-inner-container {
    text-align: center;
    display: block;
    margin: 0 20%;

    .login-input-container {
      position: relative;
      .vf-input__container {
        width: calc(100% - 50px) !important;
      }

      button {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 52px;
        width: 52px;
        background: #fff;
        border: 1px solid #e8e8e9;
        cursor: pointer;
        border-radius: 0 4px 4px 0;

        &.error {
          border-color: #ffb2a4;
          box-shadow: 0 0 #f93b18;
          outline: none;
        }

        &:hover,
        &:focus {
          box-shadow: 0px 0px 7px -5px rgba(66, 68, 90, 1);
        }

        &:active {
          box-shadow: inset 0px 0px 10px -5px rgba(66, 68, 90, 1);
        }

        &:focus {
          outline: none;
        }

        &:disabled {
          cursor: not-allowed;
          box-shadow: none;
        }
      }
    }
  }
}
