@use '../../../styles/variables' as *;
@use '../ListTemplate/list-template.scss' as *;

.item-template {
  @include list-grid-common;
  position: relative;

  a {
    font-weight: 400;
    display: block;
  }

  &__ctas {
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    padding: 0 0.5rem;
    width: 10%;

    .action-btn {
      padding: 2px 6px;
    }
  }

  &:hover {
    background-color: $gray-100;

    .item-template__ctas {
      opacity: 1;
      background-color: $gray-100;
    }
  }
}
