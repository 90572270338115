@use '../../../styles/variables' as *;

$labelFontSize: 0.8rem;

.two-columns-template {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 3fr 1fr;
  margin-top: 1rem;
}

.main-column-template {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      h1 {
        margin-bottom: 0 !important;
      }

      small {
        color: $gray-500;
      }
    }

    &__ctas {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__content {
    margin-top: 1.5rem;

    &__item {
      &__label {
        font-size: $labelFontSize;
        font-weight: 500;
      }

      &__value {
        font-size: 0.9rem;
        font-weight: 300 !important;
        line-height: 1.2;
      }
    }
  }
}

.sidebar-column-template {
  padding: 0 1rem;
  font-size: 0.9rem;
  line-height: 20px;
  border-left: 1px solid $gray-200;

  &__section {
    padding: 0.5rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      &__label {
        color: $gray-500;
        font-size: $labelFontSize;
      }
    }
  }
}
